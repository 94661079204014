//css-------------------
import '../../css/share/common.scss';
import '../../css/home/welcome.scss';

//js----------------------
//components
// import( /* webpackPrefetch: true */ '../share/components/_cookie_bar');
import( /* webpackPrefetch: true */ '../share/components/_font_family');
import( /* webpackPrefetch: true */ '../share/components/_welcome_lazy');

//npm lib
import { gsap } from "gsap";
import imagesLoaded from "imagesloaded";
import { createApp } from 'vue/dist/vue.esm-browser.prod.js';
import AudioMotionAnalyzer from 'audiomotion-analyzer';


const app = createApp({
    data() {
        return {
            prev_audio_index: 0,
            audio: [],
            data_lang: data_lang,
        }
    },
    mounted() {
        const _this = this;
        // this.data_lang = data_lang;
        rec_file_row.forEach(function(o, i) {
            _this.audio[i] = _this.data_ori();
            Object.keys(o).forEach((key) => {
                _this.audio[i][key] = o[key];
            });
        });

        imagesLoaded("html, body", function() {
            gsap.to('.loading', { duration: .3, opacity: 0, display: 'none' });
        });
    },
    methods: {
        data_ori() {
            return {
                play: false,
                track: null,
                animation: null,
                container: null,
                playhead: null,
                duration: null,
                timeline: null,
                timelineWidth: null,
                source: null,
                time_duration: '00:00',
                time_current: '00:00',
            };
        },
        init_handler(index) {
            const _this = this;

            this.audio[index].track = document.getElementById('audio_' + index);
            this.audio[index].container = document.getElementById('audio_ani_' + index);
            this.audio[index].scrub = document.getElementById('scrub_' + index);
            this.audio[index].playhead = document.getElementById('playhead_' + index);
            this.audio[index].timeline = document.getElementById('timeline_' + index);
            this.audio[index].duration = this.audio[index].track.duration;
            this.audio[index].timelineWidth = this.audio[index].timeline.offsetWidth - this.audio[index].playhead.offsetWidth;
            this.audio[index].time_duration = _this.formatSecondsAsTime(this.audio[index].duration);

            if (this.audio[index].animation) return;

            this.audio[index].animation = new AudioMotionAnalyzer(_this.audio[index].container, {
                source: _this.audio[index].track,
                // colorMode: 'gradient',
                frequencyScale: 'bark', //*
                gradient: 'prism', //*
                maxDecibels: -25, //sensitivity
                minDecibels: -110, //sensitivity
                mode: 3, //Discrete frequencies
                overlay: true, //背景透明
                showBgColor: false, //背景透明
                showPeaks: false, //*
                showScaleX: false, //*
            });
            this.audio[index].animation.registerGradient('prism', {
                bgColor: '#1316A2', // background color (optional) - defaults to '#111'
                // dir: 'h', // add this property to create a horizontal gradient (optional)
                colorStops: [ // list your gradient colors in this array (at least one color is required)
                    '#1316A2', // colors can be defined in any valid CSS format
                    // '#000', // colors can be defined in any valid CSS format
                    { color: '#1316A2', pos: .6 }, // in an object, use `pos` to adjust the offset (0 to 1) of a colorStop
                    { color: '#000', level: .5 } // use `level` to set the max bar amplitude (0 to 1) to use this color
                ]
            });
        },
        timeline_handler(index, event) {
            this.moveplayhead(index, event);
            this.audio[index].track.currentTime = this.audio[index].duration * this.clickPercent(index, event);
        },
        moveplayhead(index, event) {

            let newMargLeft = event.clientX - this.getPosition(this.audio[index].timeline);

            if (newMargLeft >= 0 && newMargLeft <= this.audio[index].timelineWidth) {
                this.audio[index].playhead.style.marginLeft = newMargLeft / 16 + "rem";
            }
            if (newMargLeft < 0) {
                this.audio[index].playhead.style.marginLeft = "0px";
            }
            if (newMargLeft > this.audio[index].timelineWidth) {
                this.audio[index].playhead.style.marginLeft = this.audio[index].timelineWidth / 16 + "rem";
            }
        },
        getPosition(el) {
            return el.getBoundingClientRect().left;
        },
        clickPercent(index, event) {
            // returns decimal number of the total timelineWidth
            return (event.clientX - this.getPosition(this.audio[index].timeline)) / this.audio[index].timelineWidth;
        },
        timeUpdate(index) {
            let playPercent = this.audio[index].timelineWidth * (this.audio[index].track.currentTime / this.audio[index].duration) / 16;
            this.audio[index].playhead.style.marginLeft = playPercent + "rem";
            this.audio[index].scrub.style.width = playPercent + "rem";

            this.audio[index].time_current = this.formatSecondsAsTime(Math.floor(this.audio[index].track.currentTime));

            if (this.audio[index].time_current == this.audio[index].time_duration) {
                gsap.to('.block_' + index, { duration: .5, opacity: 1, ease: 'power0.out' });
                this.audio[index].play = false;
            }
        },
        formatSecondsAsTime(secs) {
            let hr = Math.floor(secs / 3600);
            let min = Math.floor((secs - (hr * 3600)) / 60);
            let sec = Math.floor(secs - (hr * 3600) - (min * 60));

            if (min < 10) {
                min = "0" + min;
            }
            if (sec < 10) {
                sec = "0" + sec;
            }

            return min + ':' + sec;
        },
        audio_play(index) {
            this.audio_pause(this.prev_audio_index);

            if (this.audio[index].time_current == '00:00' || this.audio[index].time_current == this.audio[index].time_duration) {
                this.audio[index].track.currentTime = 1.8;
            }
            this.audio[index].track.play();
            gsap.to('.block_' + index, { duration: .5, opacity: 0, ease: 'power0.out' });
            this.audio[index].play = true;

            this.prev_audio_index = index;
        },
        audio_pause(index) {
            this.audio[index].track.pause();
            this.audio[index].play = false;
        },
    },
}).mount('#app');